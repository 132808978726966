export enum UserApplicationRole {
	ADMIN = 'admin',
	CUSTOMER = 'customer',
	SELLER = 'seller',
}

export interface IUserApplication {
	user: IUser | string
	application: IApplication | string
	balance: number
	role: UserApplicationRole
	updatedAt: Date
	createdAt: Date
}

export interface IApplication {
	_id: string
	user: IUser | string
	balance?: number
	isOwner?: boolean
	isSeller?: boolean
	isCustomer?: boolean
	apiKey: string
	name: string
	description?: string
	userApplication?: IUserApplication
	image?: string
	slug: string
	updatedAt: Date
	createdAt: Date
	deletedAt?: Date
}
